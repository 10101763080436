<template>
    <v-item-group
        v-model="selectedId"
        class="saved-payment-method-selector mb-4"
        selected-class="bg-primary-opacify-1"
        aria-label="Saved Payment Method Selector"
        mandatory
        :disabled="disabled || selectionChangePending"
    >
        <v-skeleton-loader
            v-if="loading"
            class="skeleton-item w-100"
            type="image"
        />
        <v-item
            v-else
            v-for="method in items"
            :key="method.uuid"
            :value="method.uuid"
            v-slot="{ selectedClass, toggle, isSelected }"
        >
            <v-card
                class="saved-method-item gray-500"
                :class="selectedClass"
                :aria-selected="isSelected"
                elevation="0"
                border
                @click="toggle"
            >
                <div
                    v-if="hasMultiple"
                    class="saved-method-item__radio"
                    :class="{
                        'saved-method-item__radio--selected': isSelected,
                    }"
                />
                <div class="saved-method-item__main">
                    <div class="saved-method-item__start">
                        <div class="saved-method-item__icon">
                            <img
                                class="saved-method-item__icon-image"
                                :src="resolveIconUrl(method, theme)"
                                role="presentation"
                            />
                        </div>
                        <span class="saved-method-item__title">
                            {{ resolveTitle(method) }}
                        </span>
                    </div>
                    <div class="saved-method-item__end">
                        <template
                            v-if="
                                method?.payment_method === 'globalcards' ||
                                method?.payment_method === 'globalcards_checkout'
                            "
                        >
                            <span
                                class="saved-method-item__meta saved-method-item__meta--number"
                            >
                                •••• {{ method.description.card_last_four }}
                            </span>
                            <span
                                class="saved-method-item__meta saved-method-item__meta--expiry"
                            >
                                {{ method.description.card_expiry }}
                            </span>
                            <span
                                class="saved-method-item__meta saved-method-item__meta--postcode"
                            >
                                {{ method.description.postal_code }}
                            </span>
                        </template>
                        <template v-if="method?.payment_method === 'paypal'">
                            <span
                                class="saved-method-item__meta saved-method-item__meta--postcode"
                            >
                                {{ method.description.postal_code }}
                            </span>
                        </template>
                        <span
                            class="saved-method-item__remove border-b text-bold"
                            role="button"
                            @click.stop="() => beginRemove(method)"
                        >
                            {{ t("payment.saved_payments.method_remove") }}
                        </span>
                    </div>
                    <v-progress-linear
                        class="saved-method-item__loader"
                        :active="selectionChangePending && isSelected"
                        indeterminate
                        location="bottom"
                    />
                </div>
            </v-card>
        </v-item>
        <v-skeleton-loader
            v-if="
                selectedPaymentMethodIdent === 'globalcards' ||
                selectedPaymentMethodIdent === 'globalcards_checkout'
            "
            class="skeleton-button w-100"
            type="image"
            :loading="loading"
        >
            <v-btn
                class="w-100 d-flex align-center justify-center gray-500 btn-add-new-card"
                border
                height="30px"
                @click="addNew"
            >
                {{ t("payment.saved_payments.method_add_card") }}
            </v-btn>
        </v-skeleton-loader>
    </v-item-group>
    <v-dialog v-model="showRemovalModal" width="auto">
        <v-card
            class="remove-modal"
            max-width="400"
            :text="t('payment.saved_payments.remove_prompt')"
        >
            <template v-slot:actions>
                <v-spacer />
                <v-btn
                    class="ms-auto"
                    color="gray-600"
                    @click="showRemovalModal = false"
                >
                    {{ t("buttons.cancel") }}
                </v-btn>
                <v-btn class="ms-auto" color="primary" @click="confirmRemove">
                    {{ t("buttons.confirm") }}
                </v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeUnmount, onMounted } from "vue";
import { assert, useVModel } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { ThemeInstance, useTheme } from "vuetify";
import { type SavedPaymentMethod } from "@/types/SavedPaymentMethod";
import { usePaymentStore } from "@/store/payment";
import { useCustomerStore } from "@/store/customer";
import { useBasketStore } from "@/store/basket";

const { t } = useI18n();

interface Props {
    items: SavedPaymentMethod[];
    modelValue: SavedPaymentMethod | null;
    selectedPaymentMethodIdent?: string;
    loading?: boolean;
    disabled?: boolean;
    selectionChangePending?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
    disabled: false,
    selectionChangePending: false,
});

const theme = useTheme();

const emit = defineEmits(["update:modelValue", "change", "addNewMethod"]);

const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const customerStore = useCustomerStore();
const selected = useVModel(props, "modelValue", emit);

const showRemovalModal = ref(false);
const methodPendingRemoval = ref<SavedPaymentMethod | null>(null);

const hasMultiple = computed(() => props.items && props.items.length > 1);

const selectedId = computed<SavedPaymentMethod["uuid"] | null>({
    get() {
        const value = props.items.find(
            (item) => item.uuid === selected.value?.uuid,
        );
        return value?.uuid ?? null;
    },
    set(id) {
        const value = props.items.find((item) => item.uuid === id) ?? null;
        selected.value = value;
        emit("change", value);
    },
});

const resolveIconUrl = (method: SavedPaymentMethod, theme: ThemeInstance) => {
    let iconName = "";

    switch (method.payment_method) {
        case "paypal":
            iconName = "paypal";
            break;
        case "globalcards":
        case "globalcards_checkout":
            iconName = method.description.card_brand.toLowerCase();
            break;
        default:
            iconName = "none";
            console.warn(
                "could not resolve icon for saved payment method",
                method,
            );
            break;
    }

    if (["visa", "paypal"].includes(iconName))
        iconName += "-" + theme.name.value;

    return `/img/saved-method-icons/${iconName}.png`;
};

const resolveTitle = (method: SavedPaymentMethod) => {
    switch (method.payment_method) {
        case "paypal":
            return method.description.paypal_email;
        case "globalcards":
        case "globalcards_checkout":
            const brand = method.description.card_brand.toLowerCase();
            return brand[0].toUpperCase() + brand.slice(1);
        default:
            return "";
    }
};

const beginRemove = async (method: SavedPaymentMethod) => {
    methodPendingRemoval.value = method;
    showRemovalModal.value = true;
};

const confirmRemove = async () => {
    const method = methodPendingRemoval.value;
    assert(method !== null);
    assert(showRemovalModal.value);
    await customerStore.removeSavedPaymentMethod(
        basketStore.basketId as string,
        method!.uuid,
    );
    showRemovalModal.value = false;
    methodPendingRemoval.value = null;
};

const addNew = () => {
    emit("addNewMethod");
};

onMounted(() => {
    paymentStore.setFieldsReady(true);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>

<style lang="scss" scoped>
@use "@/styles/settings";

.saved-payment-method-selector {
    display: grid;
    gap: 1rem;
}

.saved-method-item {
    display: flex;
    align-items: center;
    padding-inline: 18px;
    padding-block: 8px;
    position: relative;

    @media (min-width: 460px) {
        height: 40px;
        padding-block: 0;
    }

    &__main {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__start {
        display: flex;
        gap: 12px;
        width: 100%;

        @media (min-width: 460px) {
            width: auto;
        }
    }

    &__end {
        display: flex;
        flex-wrap: wrap;
        gap: 2px 12px;
        align-items: center;
        width: 100%;

        @media (min-width: 460px) {
            width: auto;
            flex-wrap: nowrap;
            margin-inline-start: auto;
        }
    }

    &__radio {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: rgb(var(--v-theme-gray-400));
        box-sizing: border-box;
        margin-inline-end: 12px;

        // TODO: (general) really need to take a look at light theme config because the shades of gray used never look right - mockups only ever consider dark theme
        @at-root .v-theme--light & {
            background: rgb(var(--v-theme-gray-900));
        }

        &--selected {
            background: #fff;
            border: 5px solid rgb(var(--v-theme-primary));
        }
    }

    &__icon-image {
        display: block;
        height: 20px;
    }

    &__meta {
        font-variant-numeric: tabular-nums;
    }

    &__remove {
        margin-inline-start: auto;
    }

    &__loader {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.skeleton-item :deep(.v-skeleton-loader__image) {
    height: 40px;
    border-radius: settings.$card-border-radius;
}

.skeleton-button :deep(.v-skeleton-loader__image) {
    height: 30px;
    border-radius: settings.$card-border-radius;
}

.btn-add-new-card {
    font-size: 12px;
}

.remove-modal :deep(.v-card-text) {
    padding-bottom: 0 !important;
}

.remove-modal :deep(.v-btn__content) {
    font-size: 14px !important;
}
</style>
