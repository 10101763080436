<template>
    <div class="container">
        <SkeletonVerifyPage :loading="!appStore.isReady">
            <VerifyLayout
                :title="t('verify.email.title')"
                :subtitle="
                    t('verify.email.subtitle', {
                        email: form?.fields.email.model,
                    })
                "
            >
                <div class="d-flex flex-row align-center">
                    <v-icon
                        icon="custom:email"
                        size="36px"
                        class="pa-2"
                    ></v-icon>
                    <v-otp-input
                        v-model="code"
                        placeholder="-"
                        max-height="50px"
                        max-width="265px"
                        rounded="2px"
                        :error="errorMessage !== ''"
                        @finish="submitCode"
                    ></v-otp-input>
                    <Loader
                        v-if="isSubmitting"
                        class="ma-2"
                        size="20px"
                        border-size="3px"
                    ></Loader>
                </div>
                <div class="text-error mt-n2" style="height: 24px">
                    {{ errorMessage }}
                </div>

                <span
                    role="button"
                    @click="resendCode"
                    class="mt-2 text-primary border-b border-primary border-opacity-100"
                    :class="{
                        'link-disabled': isSubmitting,
                    }"
                >
                    {{ t("verify.otp.resend") }}
                </span>

                <template #actions>
                    <v-btn
                        color="primary"
                        width="140px"
                        :disabled="code.length < 6 || isSubmitting"
                        @click="submitCode"
                    >
                        {{ t("buttons.continue") }}
                    </v-btn>
                    <span
                        role="button"
                        @click="skip"
                        class="link-secondary mt-2 border-b border-opacity-100"
                    >
                        {{ t("buttons.proceed_without_saving") }}
                    </span>
                </template>

                <template #footer>
                    <v-alert
                        variant="tonal"
                        type="success"
                        class="border border-success justify-center text-uppercase"
                        :text="t('payment.order_complete')"
                        density="compact"
                        height="38"
                        width="100%"
                    ></v-alert>
                </template>
            </VerifyLayout>
        </SkeletonVerifyPage>
    </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from "vue";
import { usePaymentStore } from "@/store/payment";
import Loader from "@/components/app/Loader.vue";
import { useI18n } from "vue-i18n";
import SkeletonVerifyPage from "@/components/skeleton/SkeletonVerifyPage.vue";
import { useAppStore } from "@/store/app";
import VerifyLayout from "@/components/verify/VerifyLayout.vue";
import api from "@/services/api";
import { useRoute, useRouter } from "vue-router";
import { until } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useBasketStore } from "@/store/basket";
import { hasPaymentDeclined } from "@/helpers/payment";

const appStore = useAppStore();
const paymentStore = usePaymentStore();
const basketStore = useBasketStore();
const { t } = useI18n();

const form = paymentStore.getActiveForm();

const route = useRoute();
const router = useRouter();

const basketId = route.params.basketId as string;
const code = ref("");
const errorMessage = ref("");
const isSubmitting = ref(false);
const { isReady: basketIsReady, payment } = storeToRefs(basketStore);

const resendCode = async () => {
    code.value = "";
    isSubmitting.value = true;
    errorMessage.value = "";

    try {
        await api.post(`/baskets/${basketId}/verify/email/send-code`);
    } catch (error) {
        console.error(error);
        // TODO: will the backend give us an error message here? Or should we just display a generic one?
        errorMessage.value = "Could not resend verification code";
    } finally {
        isSubmitting.value = false;
    }
};

const submitCode = async () => {
    isSubmitting.value = true;

    try {
        // TODO: This should be moved into its own service/store function
        const response = await api.post(`/baskets/${basketId}/verify/email`, {
            code: code.value,
        });
        router.push({ name: "enter-phone" });
        isSubmitting.value = false;
        errorMessage.value = "";
    } catch (error) {
        console.error(error);
        // TODO: will the backend give us an error message here? Or should we just display a generic one?
        errorMessage.value = "Could not verify code";
    } finally {
        isSubmitting.value = false;
    }
};

const skip = () => {
    router.push({ name: "payment-completed" });
};

onBeforeMount(async () => {
    // Ensure basket and app data is loaded before proceeding
    await until(basketIsReady).toBe(true);

    // The email verification will redirect if there is no payment
    // Or if the payment has been declined
    // Or if the user is already verified and so cannot change their email
    if (
        !payment.value ||
        hasPaymentDeclined(payment.value) ||
        payment.value.paidByVerifiedCustomer
    ) {
        router.push({ name: "payment" });
    }
});
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.v-alert {
    margin-top: 120px;
}

:deep(.v-alert__prepend) {
    margin-inline-end: 6px;
}

.v-skeleton-loader {
    width: 100%;
    max-width: 478px;
    align-self: flex-start;
    margin-top: 95px;

    @media (min-width: 1024px) {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.link-secondary {
    color: rgb(var(--v-theme-gray-600));
    border-color: currentColor !important;
}

.link-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
