import { isAxiosError, handleError } from "@/services/api";
import {
    deleteCoupon,
    deleteGiftCard,
    deletePackageFromBasket,
    getBasketData,
    getUsername,
    postCoupon,
    postCreatorCode,
    postGiftCard,
    updateBasketData,
} from "@/services/basket";
import { addUpsellToBasket } from "@/services/upsell";
import { ErrorResponse } from "@/types/Api";
import { BasketData, Discount, PriceDetails, TaxDetails } from "@/types/Basket";
import BasketItem from "@/types/BasketItem";
import { Coupon } from "@/types/Coupon";
import { GiftCard } from "@/types/GiftCard";
import UpsellItem from "@/types/UpsellItem";
import { useAsyncState, computedAsync } from "@vueuse/core";
import { acceptHMRUpdate, defineStore } from "pinia";
import {
    computed,
    ref,
    type Ref,
    type ComputedRef,
    type WritableComputedRef,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { BillingAddress } from "@/types/BillingAddress";
import { usePaymentMethodStore } from "./payment-method";
import { useAppStore } from "./app";
import { RecurringItem } from "@/types/RecurringItems";
import { fingerprint, getRequestId } from "@/services/fingerprint";
import { initStripeRadarsession } from "@/services/stripe";

interface State {
    basketId: ComputedRef<string | undefined>;
    basketData: Ref<BasketData | null | undefined>;
    items: WritableComputedRef<BasketItem[]>;
    price: ComputedRef<PriceDetails | null>;
    isReady: Ref<boolean>;
    isLoading: Ref<boolean>;
    error: Ref<unknown>;
    creatorCode: ComputedRef<Discount | undefined>;
    subTotal: ComputedRef<number>;
    salesTax: ComputedRef<number>;
    total: ComputedRef<number>;
    balance: ComputedRef<number>;
    giftCards: ComputedRef<GiftCard[]>;
    coupons: ComputedRef<Coupon[]>;
    shouldShowLoader: ComputedRef<boolean>;
    address: ComputedRef<BillingAddress | null>;
    isComplete: ComputedRef<boolean>;
    payment: ComputedRef<BasketData["payment"]>;
    isPaymentInProgress: Ref<boolean>;
    completeUrl: ComputedRef<string | null>;
    cancelUrl: ComputedRef<string | null>;
    showConversion: ComputedRef<boolean>;
    totalWithConversion: ComputedRef<number>;
    isRecurring: ComputedRef<boolean>;
    recurringNextPaymentDate: ComputedRef<string | null>;
    recurringPeriod: ComputedRef<string | null>;
    recurringAmount: ComputedRef<number | null>;
    recurringItems: ComputedRef<RecurringItem[]>;
    isPaymentMethodUpdate: ComputedRef<boolean>;
    isBasketCompleted: ComputedRef<boolean>;
    tax: ComputedRef<TaxDetails | null>;
    username: Ref<string | null>;
    currencyCode: ComputedRef<string>;
    isCurrencySupported: ComputedRef<boolean>;
    radarSession: Ref<Record<any, any> | null>;
    fetchBasket: (basketId: string) => Promise<BasketData | null | undefined>;
    applyCreatorCode: (code: string) => void;
    removeCreatorCode: () => void;
    applyDiscountCode: (code: string) => void;
    removeGiftCard: (giftCardId: GiftCard["id"]) => Promise<void>;
    removeCoupon: (code: string) => Promise<void>;
    addUpsell: (
        upsellId: UpsellItem["uuid"],
        options?: Record<string, string>,
    ) => Promise<void>;
    removePackage: (packageId: string) => Promise<void>;
    getItemByPackageId: (packageId: number) => BasketItem | undefined;
    updateBasket: (data: Record<string, string>) => Promise<void>;
    setPaymentInProgress: (value: boolean) => void;
    setStripeRadarSession: () => Promise<void>;
    setBasketFingerprint: () => Promise<void>;
}

export const useBasketStore = defineStore("basket", function (): State {
    const router = useRouter();
    const route = useRoute();
    const paymentMethodStore = usePaymentMethodStore();
    const appStore = useAppStore();

    const basketId = computed(() => {
        return router?.currentRoute.value.params?.basketId as string;
    });

    const isInitialLoad = ref<boolean>(true);
    const isLoading = ref<boolean>(true);

    const { state, isReady, execute, error } = useAsyncState<
        BasketData | null,
        [{ basketId: string }],
        false
    >(
        (args) => {
            return getBasketData(args?.basketId);
        },
        null,
        { immediate: false, resetOnExecute: false, shallow: false },
    );

    const basketData = computed(() => state.value);

    // Getters
    const items = computed({
        get() {
            return state.value?.basket.items ?? [];
        },
        set(value) {
            if (state.value?.basket.items && value) {
                state.value.basket.items = value;
            }
        },
    });

    const price = computed(() => state.value?.basket.price ?? null);

    const currencyCode = computed(() => {
        return (
            price.value?.currencyCode ?? appStore.config?.currency.code ?? "USD"
        );
    });

    const isCurrencySupported = computed(() => {
        if (
            !paymentMethodStore.selectedPaymentMethodDetails ||
            paymentMethodStore.selectedPaymentMethodDetails?.currency === ""
        ) {
            return true;
        }

        return (
            paymentMethodStore.selectedPaymentMethodDetails?.currency ===
            currencyCode.value
        );
    });

    const subTotal = computed((): number => {
        return price.value?.subTotal ?? 0;
    });

    const salesTax = computed((): number => {
        if (typeof price.value?.tax === "number") {
            return price.value?.tax ?? 0;
        }

        if (!price.value?.tax.taxAmount) {
            return 0;
        }

        return price.value.tax.taxAmount;
    });

    const showConversion = computed(() => {
        if (!paymentMethodStore.selectedPaymentMethodDetails?.currency) {
            return false;
        }

        return (
            currencyCode.value !==
            paymentMethodStore.selectedPaymentMethodDetails?.currency
        );
    });

    const totalWithConversion = computed(() => {
        return (
            paymentMethodStore.selectedPaymentMethodDetails?.checkoutAmount ?? 0
        );
    });

    // The total of the basket, including tax but before any discounts
    const total = computed((): number => {
        return price.value?.total ?? 0;
    });

    // The balance to pay, this should be used to display what the user has to pay
    const balance = computed((): number => {
        return price.value?.balance ?? 0;
    });

    const isComplete = computed((): boolean => {
        return state.value?.basket.isComplete ?? false;
    });

    const address = computed((): BillingAddress | null => {
        return state.value?.basket.address ?? null;
    });

    const tax = computed((): TaxDetails | null => {
        if (typeof price.value?.tax === "number") return null;
        else return price.value?.tax ?? null;
    });

    // TODO: Remove in favour of using recurringItems array
    const isRecurring = computed((): boolean => {
        return state.value?.basket.recurring ?? false;
    });

    // TODO: Remove in favour of using recurringItems array
    const recurringNextPaymentDate = computed((): string | null => {
        return state.value?.basket.recurringNextPaymentDate ?? null;
    });

    // TODO: Remove in favour of using recurringItems array
    const recurringPeriod = computed((): string | null => {
        return state.value?.basket.recurringPeriod ?? null;
    });

    // TODO: Remove in favour of using recurringItems array
    const recurringAmount = computed((): number | null => {
        if (!state.value?.basket.price.recurring) {
            return null;
        }

        const { amount, tax } = state.value.basket.price.recurring;

        return amount + tax;
    });

    const recurringItems = computed((): RecurringItem[] => {
        return state.value?.basket.recurringItems ?? [];
    });

    const isPaymentMethodUpdate = computed((): boolean => {
        return state.value?.basket.isPaymentMethodUpdate ?? false;
    });

    const giftCards = computed((): GiftCard[] => state?.value?.giftCards ?? []);
    const coupons = computed((): Coupon[] => state?.value?.coupons ?? []);

    const discounts = computed((): Discount[] => state.value?.discounts ?? []);

    const creatorCode = computed(() => {
        const discount = discounts.value.find(
            (discount) => discount.type === "creator_code",
        );

        return discount ?? basketData.value?.creatorCode
            ? ({
                  id: "",
                  code: basketData.value?.creatorCode,
                  discountAmount: 0,
                  type: "",
              } as Discount)
            : undefined;
    });

    const completeUrl = computed(() => state.value?.basket.completeUrl ?? null);
    const cancelUrl = computed(() => state.value?.basket.cancelUrl ?? null);

    const payment = computed(() => {
        return state.value?.payment ?? null;
    });

    const shouldShowLoader = computed(() => {
        if (appStore.isConfigLoading) {
            return true;
        }

        return isInitialLoad.value && isLoading.value;
    });

    // Use session storage to store the payment in progress state between page refreshes/redirects
    const isPaymentInProgress = ref(false);

    const isBasketCompleted = computed(() => {
        if (isPaymentMethodUpdate.value) {
            return false;
        }

        return isComplete.value && !isPaymentInProgress.value;
    });

    const username = computedAsync(async () => {
        const gameTypeId = appStore.config?.gameTypeId;
        const userId = basketData.value?.userId;
        if (gameTypeId && userId) return await getUsername(gameTypeId, userId);
        return null;
    });

    async function fetchBasket(basketId: string) {
        isLoading.value = true;

        const data = await execute(0, { basketId });

        // If the basket is complete, redirect to the complete page
        if (
            isBasketCompleted.value &&
            route.name &&
            route.name !== "cashapp" &&
            route.name !== "payment-completed" &&
            route.name !== "payment-error" &&
            route.name !== "enter-phone" &&
            route.name !== "verify-email" &&
            route.name !== "verify-phone"
        ) {
            await router.push({
                name: "basket-complete",
                params: { basketId },
            });
        }

        isInitialLoad.value = false;
        isLoading.value = false;
        return data;
    }

    async function updateBasket(data: Record<string, string>) {
        try {
            state.value = await updateBasketData(basketId.value, data);
        } catch (error) {
            handleError(error);
        }
    }

    // Refreshes the basket and any other data that needs to be refreshed
    async function refreshBasket(): Promise<void> {
        try {
            Promise.all([
                paymentMethodStore.fetchSelectedPaymentMethodDetails(),
                fetchBasket(basketId.value),
            ]);
        } catch (error) {
            handleError(error);
        }
    }

    async function applyCreatorCode(code: string) {
        try {
            await postCreatorCode(basketId.value, code);
        } catch (error) {
            handleError(error, { showToast: false });
        }

        // Once creator code is successfully applied reload the basket
        await fetchBasket(basketId.value);
    }

    async function removeCreatorCode() {
        try {
            await postCreatorCode(basketId.value, "");
        } catch (error) {
            handleError(error);
        }

        // Once creator code is successfully applied reload the basket
        await fetchBasket(basketId.value);
    }

    async function applyCouponCode(code: string) {
        try {
            await postCoupon(basketId.value, code);
        } catch (error) {
            handleError(error, { showToast: false });
        }
    }

    async function applyDiscountCode(code: string) {
        try {
            // Try to apply the giftcard first
            await postGiftCard(basketId.value, code);
        } catch (error) {
            if (isAxiosError<ErrorResponse>(error)) {
                // If this post failed, try the coupon code endpoint
                if (error?.response?.status === 404) {
                    await applyCouponCode(code);
                }
            } else {
                throw error;
            }
        }

        // Once discount is applied reload the basket
        await fetchBasket(basketId.value);
        await paymentMethodStore.fetchPaymentMethods();
        // Set the first payment method as selected
        paymentMethodStore.setSelectedPaymentMethodIndex(0);
    }

    async function removeGiftCard(giftCardId: GiftCard["id"]) {
        try {
            await deleteGiftCard(basketId.value, giftCardId);
        } catch (error) {
            handleError(error);
        }

        await fetchBasket(basketId.value);
        await paymentMethodStore.fetchPaymentMethods();
        // Set the first payment method as selected
        paymentMethodStore.setSelectedPaymentMethodIndex(0);
    }

    async function removeCoupon(code: string) {
        try {
            await deleteCoupon(basketId.value, code);
        } catch (error) {
            handleError(error);
        }

        await fetchBasket(basketId.value);
        await paymentMethodStore.fetchPaymentMethods();
        // Set the first payment method as selected
        paymentMethodStore.setSelectedPaymentMethodIndex(0);
    }

    async function addUpsell(
        upsellId: UpsellItem["uuid"],
        options?: Record<string, string>,
    ) {
        try {
            await addUpsellToBasket(basketId.value, upsellId, options);
        } catch (error) {
            handleError(error);
        }

        await refreshBasket();
    }

    async function removePackage(basketItemId: string) {
        try {
            await deletePackageFromBasket(basketId.value, basketItemId);
        } catch (error) {
            handleError(error);
        }

        await refreshBasket();
    }

    function getItemByPackageId(packageId: number) {
        return items.value.find((item) => item.package === packageId);
    }

    function setPaymentInProgress(value: boolean) {
        isPaymentInProgress.value = value;
    }

    async function setBasketFingerprint(): Promise<void> {
        // If the basket already has a fingerprint, don't generate a new one
        if (basketData.value?.basket.fingerprint) return;

        const requestId = await getRequestId();

        if (!requestId) return;

        await fingerprint(basketId.value, requestId);
    }

    const radarSession: Ref<Record<any, any> | null> = ref(null);

    async function setStripeRadarSession() {
        try {
            const publishableKey = appStore.config?.services.stripe.publicKey;

            if (!publishableKey) {
                throw new Error("No publishable key found in config");
            }

            radarSession.value = await initStripeRadarsession(publishableKey);
        } catch (error) {
            // If the radar session fails to load, log the error but don't throw it
            // as it's not critical to the user experience
            console.error(error);
        }
    }

    return {
        // State
        basketId,
        basketData,
        items,
        price,
        isReady,
        isLoading,
        error,
        creatorCode,
        subTotal,
        salesTax,
        total,
        balance,
        giftCards,
        coupons,
        shouldShowLoader,
        address,
        isComplete,
        payment,
        isPaymentInProgress,
        completeUrl,
        cancelUrl,
        showConversion,
        totalWithConversion,
        isRecurring,
        recurringNextPaymentDate,
        recurringPeriod,
        recurringAmount,
        recurringItems,
        isPaymentMethodUpdate,
        isBasketCompleted,
        tax,
        username,
        currencyCode,
        isCurrencySupported,
        radarSession,
        // Actions
        fetchBasket,
        applyCreatorCode,
        removeCreatorCode,
        applyDiscountCode,
        removeGiftCard,
        removeCoupon,
        addUpsell,
        removePackage,
        getItemByPackageId,
        updateBasket,
        setPaymentInProgress,
        setStripeRadarSession,
        setBasketFingerprint,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useBasketStore, import.meta.hot));
}
