<template>
    <div class="container">
        <SkeletonVerifyPage
            :loading="!appStore.isReady || !basketStore.isReady"
        >
            <VerifyLayout
                :title="t('verify.phone.title')"
                :subtitle="
                    lastThreeDigits
                        ? t('verify.phone.subtitle', {
                              lastThreeDigits: lastThreeDigits,
                          })
                        : t('verify.phone.subtitle_no_phone')
                "
            >
                <div class="d-flex flex-row align-center">
                    <v-icon
                        icon="custom:phone"
                        size="36px"
                        class="pa-2"
                    ></v-icon>
                    <v-otp-input
                        v-model="code"
                        placeholder="-"
                        max-height="50px"
                        max-width="265px"
                        rounded="2px"
                        :error="errorMessage !== ''"
                        @finish="submitCode"
                    ></v-otp-input>
                    <Loader
                        v-if="isSubmitting"
                        class="ma-2"
                        size="20px"
                        border-size="3px"
                    ></Loader>
                </div>
                <div class="text-error mt-n2" style="height: 24px">
                    {{ errorMessage }}
                </div>

                <div class="d-flex flex-row align-center border-opacity-100">
                    <span
                        v-if="allowNumberChange"
                        role="button"
                        @click="changeNumber"
                        class="mt-2 mr-3 link-secondary border-b-sm border-opacity-100"
                    >
                        {{ t("verify.otp.change_number") }}
                    </span>
                    <span
                        role="button"
                        @click="resendCode"
                        class="mt-2 ml-3 text-primary border-b-sm border-primary border-opacity-100"
                        :class="{
                            'link-disabled': isSubmitting,
                        }"
                    >
                        {{ t("verify.otp.resend") }}
                    </span>
                </div>

                <template #actions>
                    <v-btn
                        color="primary"
                        width="140px"
                        :disabled="code.length < 6 || isSubmitting"
                        @click="submitCode"
                    >
                        {{ t("buttons.continue") }}
                    </v-btn>
                    <span
                        role="button"
                        @click="skip"
                        class="link-secondary mt-2 border-b border-opacity-100"
                    >
                        {{ t("buttons.proceed_without_saving") }}
                    </span>
                </template>

                <template #footer>
                    <v-alert
                        variant="tonal"
                        type="success"
                        class="border border-success justify-center text-uppercase"
                        :text="t('payment.order_complete')"
                        density="compact"
                        height="38"
                        width="100%"
                    ></v-alert>
                </template>
            </VerifyLayout>
        </SkeletonVerifyPage>
    </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import Loader from "@/components/app/Loader.vue";
import SkeletonVerifyPage from "@/components/skeleton/SkeletonVerifyPage.vue";
import VerifyLayout from "@/components/verify/VerifyLayout.vue";
import api from "@/services/api";
import { useAppStore } from "@/store/app";
import { useCustomerStore } from "@/store/customer";
import { useBasketStore } from "@/store/basket";
import { BasketPayment } from "@/types/Basket";
import { until } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { hasPaymentDeclined } from "@/helpers/payment";

const appStore = useAppStore();
const basketStore = useBasketStore();
const customerStore = useCustomerStore();

const router = useRouter();
const { t } = useI18n();

const route = useRoute();
const basketId = route.params.basketId as string;
const code = ref("");
const errorMessage = ref("");
const isSubmitting = ref(false);

const lastThreeDigits = computed(() => customerStore.phoneNumber.slice(-3));
const { isReady: basketIsReady, payment } = storeToRefs(basketStore);

/**
 * If the customer exists, we don't allow the user to change their number.
 * This is determined by the query parameter `customerExists` or if the payment was made by a verified customer.
 */
const allowNumberChange = computed(() => {
    if (route.query.customerExists === "true") return false;
    else if ((payment.value as BasketPayment)?.paidByVerifiedCustomer)
        return false;
    else return true;
});

onBeforeMount(async () => {
    // Ensure basket and app data is loaded before proceeding
    await until(basketIsReady).toBe(true);

    // The phone verification page should only be accessible after the user has completed a payment
    if (!payment.value || hasPaymentDeclined(payment.value)) {
        router.push({ name: "payment" });
    }
});

const changeNumber = () => {
    router.push({ name: "enter-phone" });
};

const resendCode = async () => {
    code.value = "";
    isSubmitting.value = true;
    errorMessage.value = "";

    try {
        await api.post(`/baskets/${basketId}/verify/phone/send-code`);
    } catch (error) {
        errorMessage.value = t("verify.phone.resend_error");
    } finally {
        isSubmitting.value = false;
    }
};

const submitCode = async () => {
    isSubmitting.value = true;
    errorMessage.value = "";

    try {
        const response = await api.post<{ payment_method_saved: boolean }>(
            `/baskets/${basketId}/verify/phone`,
            {
                code: code.value,
            },
        );

        router.push({
            name: "payment-completed",
            query: {
                saved: response.data?.payment_method_saved?.toString(),
            },
        });
    } catch (error) {
        errorMessage.value = t("verify.phone.invalid_code");
    } finally {
        isSubmitting.value = false;
    }
};

const skip = () => {
    router.push({ name: "payment-completed" });
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.v-alert {
    margin-top: 120px;
}

:deep(.v-alert__prepend) {
    margin-inline-end: 6px;
}

.v-skeleton-loader {
    width: 100%;
    max-width: 478px;
    align-self: flex-start;
    margin-top: 95px;

    @media (min-width: 1024px) {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.link-secondary {
    color: rgb(var(--v-theme-gray-600));
    border-color: currentColor !important;
}

.link-disabled {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
