import { BasketPayment, BasketPaymentDeclined } from "@/types/Basket";

/**
 * Check if the payment has been declined
 * @param payment The payment object
 */
export function hasPaymentDeclined(
    payment: BasketPayment | BasketPaymentDeclined | null,
): payment is BasketPaymentDeclined {
    // Check if a declined "reason" in the payment object
    return (
        payment !== null &&
        (payment as BasketPaymentDeclined).reason !== undefined
    );
}
