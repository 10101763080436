import { BasketData } from "@/types/Basket";
import api, { responseBody } from "./api";
import ident from "./ident";
import { Basket } from "@/types/Basket";
import { Coupon } from "@/types/Coupon";
import { GiftCard } from "@/types/GiftCard";
import { SetupResponse } from "@/types/SetupResponse";
import { getRequestId } from "./fingerprint";
import { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/browser";

const parseBasketResponse = (data: any): BasketData => {
    // Create basket object from response
    const basket: Basket = {
        price: data.priceDetails,
        recurring: data.recurring,
        recurringNextPaymentDate: data.recurringNextPaymentDate,
        recurringPeriod: data.recurringPeriod,
        recurringItems: data.recurring_items,
        items: data.rows.map((item: any) => ({
            id: item.id,
            name: item.meta.name,
            quantity: item.quantity,
            price: item.price,
            rowprice: item.meta.rowprice,
            initialprice: item.meta.initialprice,
            package: item.package,
            image: item.image_url,
            recurring: item.recurring,
            recurringPeriod: item.recurring_period,
            recurringPrice: item.recurring_price,
        })),
        address: {
            address: data.address.address,
            country: data.address.country,
            email: data.address.email,
            firstName: data.address.first_name,
            lastName: data.address.last_name,
            name: data.address.name,
            postalCode: data.address.postal_code,
            stateId: data.address.state_id,
        },
        isComplete: data.complete,
        isPaymentMethodUpdate: data.isPaymentMethodUpdate,
        completeUrl: data.payment?.completeUrl,
        cancelUrl: data.cancel_url,
        fingerprint: data.fingerprint,
    };

    const coupons: Coupon[] = data.coupons.map((coupon: any) => ({
        id: coupon.id,
        code: coupon.related_coupon.code,
        saving: coupon.meta.savings,
    }));

    const giftCards: GiftCard[] = data.giftcards.map((giftCard: any) => ({
        id: giftCard.id,
        cardNumber: giftCard.giftcard,
        deduction: giftCard.deduction,
    }));

    const discounts = data.priceDetails.discounts;
    return {
        basket,
        coupons,
        giftCards,
        discounts,
        payment: data.payment,
        creatorCode: data.creator_code,
        userId: data.username,
    } as BasketData;
};

export const getBasketData = (basketId: string): Promise<BasketData> => {
    return api.get(`/baskets/${basketId}`).then((response) => {
        return parseBasketResponse(response.data);
    });
};

export const getUsername = (
    gameTypeId: number,
    userId: string,
): Promise<string> => {
    return ident
        .get(`/gametypes/${gameTypeId}/username/${userId}`)
        .then((response) => {
            return response.data?.username || null;
        });
};

interface BasketPutData {
    email?: string;
    name?: string;
    postalCode?: string;
    country?: string;
}

export const updateBasketData = async (
    basketId: string,
    data: BasketPutData,
) => {
    return api
        .put(`/baskets/${basketId}`, {
            email: data.email,
            name: data.name,
            postal_code: data.postalCode,
            country: data.country,
        })
        .then((response) => {
            return parseBasketResponse(response.data);
        });
};

interface BasketCodeResponse {
    message: string;
}

export const postCoupon = async (
    basketId: string,
    couponCode: string,
): Promise<BasketCodeResponse> => {
    return api
        .post(`/baskets/${basketId}/coupons/`, { couponCode })
        .then((response) => response.data);
};

export const postGiftCard = async (
    basketId: string,
    cardNumber: string,
): Promise<BasketCodeResponse> => {
    return api
        .post(`/baskets/${basketId}/giftcards/`, { cardNumber })
        .then((response) => response.data);
};

export const deleteCoupon = async (
    basketId: string,
    couponId: string,
): Promise<void> => {
    return api.delete(`/baskets/${basketId}/coupons/${couponId}`);
};

export const deleteGiftCard = async (
    basketId: string,
    giftCardId: string,
): Promise<void> => {
    return api.delete(`/baskets/${basketId}/giftcards/${giftCardId}`);
};

export const postCreatorCode = async (
    basketId: string,
    creatorCode: string,
): Promise<void> => {
    return api.post(`/baskets/${basketId}/creator-code/`, { creatorCode });
};

export const deletePackageFromBasket = async (
    basketId: string,
    basketPackageId: string,
): Promise<void> => {
    return api.delete(`/baskets/${basketId}/packages/${basketPackageId}`);
};

export const postCheckout = async <T = SetupResponse>(
    basketId: string,
    data: Record<string, any>,
): Promise<T> => {
    let requestConfig: AxiosRequestConfig = {};

    // Get the latest basket data before checkout
    try {
        const { basket } = await getBasketData(basketId);

        // If there is no fingerprint get a new requestId to allow the backend to generate one
        if (!basket.fingerprint) {
            const requestId = await getRequestId();

            requestConfig = {
                headers: {
                    "X-R-Id": requestId,
                },
            };
        }
    } catch (error) {
        Sentry.captureMessage(
            "Failed to create requestId when checking out, payment will likely fail",
        );
    }

    return api
        .post(`/baskets/${basketId}/checkout/`, data, requestConfig)
        .then(responseBody);
};

export const postPaymentMethod = async (
    basketId: string,
    data: Record<string, string>,
): Promise<void> => {
    return api
        .post(`/baskets/${basketId}/payment-method`, data)
        .then(responseBody);
};
