<template>
    <div class="container">
        <div v-if="loading" class="payment-waiting">
            <Loader class="mb-8" />

            <div class="text-center mb-11">
                {{ t("ui.loading") }}
            </div>
        </div>

        <Evervault3DS
            v-if="payload?.data"
            v-show="!loading"
            mode="iframe"
            :sessionId="payload.data['3ds_id']"
            @success="onSuccess"
            @failure="onSuccess"
            @error="onSuccess"
        ></Evervault3DS>
    </div>
</template>

<script setup lang="ts">
import Loader from "@/components/app/Loader.vue";
import Evervault3DS from "@/components/payment/evervault/Evervault3DS.vue";
import { isAxiosError } from "@/services/api";
import { useBasketStore } from "@/store/basket";
import { ErrorResponse } from "@/types/Api";
import {
    ThreeDSecureMessage,
    ThreeDSecureMessageType,
    ThreeDSecurePayload,
} from "@/types/ThreeDSecure";
import { useEventListener } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePaymentStore } from "@/store/payment";

const payload: Ref<ThreeDSecurePayload | undefined> = ref();

const basketStore = useBasketStore();
const paymentStore = usePaymentStore();

const { t } = useI18n();

const loading = ref(true);

function sendMessage(type: ThreeDSecureMessageType, payload?: any) {
    if (import.meta.env.DEV) {
        console.log("Sending message", type, payload);
    }

    window.opener.postMessage({ type, payload }, window.location.origin);
}

// Listen for messages from the parent window
useEventListener(
    window,
    "message",
    (event: MessageEvent<ThreeDSecureMessage>) => {
        if (event.origin !== window.location.origin) return;

        if (event.data.type === "tbx_3ds_payload") {
            payload.value = event.data.payload;

            loading.value = false;
        }
    },
);

async function onSuccess() {
    if (!payload.value) {
        sendMessage("tbx_3ds_error", { message: "No payload found" });
        return;
    }

    if (!basketStore.basketId) {
        sendMessage("tbx_3ds_error", { message: "No basket data found" });
        return;
    }

    const formData = payload.value.form;

    loading.value = true;

    // 3DS is complete and payment can be finalized
    try {
        await paymentStore.makePayment(
            {
                // Default to the props
                ...formData,
                // Add the 3DS txn_id to the payment
                txn_id: payload.value.data.txn_id,
                evervault_3d_secure: true,
            },
            // TODO: Fallback is not a great name here. This should probably just be paymentMethodIdent
            { paymentMethodFallback: payload.value.paymentMethodIdent },
        );
    } catch (error) {
        let message = t("error.unexpected");
        let response;

        if (isAxiosError(error)) {
            response = error.response as AxiosResponse<ErrorResponse>;
        }

        sendMessage("tbx_3ds_error", {
            message: response?.data.message ?? message,
        });

        return;
    }

    // Send a message to the parent window to indicate that the 3DSecure process is complete
    sendMessage("tbx_3ds_complete");
}

function onFail() {
    sendMessage("tbx_3ds_failed");
}

function onError(error: { message: string }) {
    sendMessage("tbx_3ds_error", { message: error.message });
}

onMounted(async () => {
    // Send a message to the parent window to indicate that the 3DSecure page is ready
    sendMessage("tbx_3ds_ready");
});
</script>

<style scoped lang="scss">
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}
.payment-waiting {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 475px;
}
</style>
