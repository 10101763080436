<template>
    <form class="ma-n4 pb-5" @submit.prevent="onSubmit">
        <v-container fluid class="pb-0">
            <SkeletonLoaderForm
                v-if="showSkeleton"
                class="email-skeleton"
                :quantity="1"
                :loading="true"
            >
            </SkeletonLoaderForm>
            <div
                v-else
                ref="emailInputMount"
                :style="{
                    visibility: showSkeleton ? 'hidden' : 'visible',
                }"
            ></div>

            <v-row
                class="payment-methods-container"
                :class="{ 'payment-methods-container--disabled': isDisabled }"
                v-if="!isFree"
            >
                <v-col>
                    <PaymentMethodSelector
                        :items="paymentMethodStore.slicedPaymentMethods"
                        v-model="paymentMethodStore.selectedPaymentMethod"
                        :limit-items-shown="true"
                        :num-total-items="paymentMethodStore.items.state.length"
                        :cols="smAndUp ? 4 : 2"
                        :loading="
                            paymentMethodStore.items.isLoading || showSkeleton
                        "
                        :disabled="paymentMethodStore.isPaymentMethodLoading"
                    />
                </v-col>
            </v-row>

            <div
                class="payment-details"
                :style="{
                    overflow: showSkeleton ? 'hidden' : 'visible',
                    maxHeight: showSkeleton ? '450px' : undefined,
                }"
                :class="{ 'payment-details--disabled': isDisabled }"
            >
                <SkeletonLoaderForm
                    v-if="showSkeleton"
                    class="payment-details-skeleton"
                    :quantity="3"
                    :loading="true"
                >
                </SkeletonLoaderForm>

                <div
                    v-if="
                        !isDataLoading &&
                        paymentMethodStore.selectedPaymentMethodDetails
                    "
                    :style="{
                        visibility: showSkeleton ? 'hidden' : 'visible',
                    }"
                >
                    <Fields
                        :payment-method-details="
                            paymentMethodStore.selectedPaymentMethodDetails
                        "
                        :emailInputMountTarget="emailInputMount"
                    />

                    <v-row>
                        <v-col ref="submitButtonContainer">
                            <v-btn
                                v-if="shouldShowSubmitButton"
                                color="primary"
                                type="submit"
                                width="100%"
                                :loading="isSubmitting"
                                :disabled="appStore.isApiRunning"
                                data-testid="payment-submit"
                            >
                                {{ submitButtonLabel }}
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div ref="secondaryActionsContainer"></div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-container>
    </form>
</template>

<script setup lang="ts">
import { ErrorResponse } from "@/types/Api";
import Fields from "@/components/payment/PaymentFields.vue";
import PaymentMethodSelector from "@/components/payment/PaymentMethodSelector.vue";
import SkeletonLoaderForm from "@/components/skeleton/SkeletonLoaderForm.vue";
import { useZoid } from "@/composables/useZoid";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";
import router from "@/plugins/router";
import { isAxiosError, throwErrorIfNecessary } from "@/services/api";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { usePaymentStore } from "@/store/payment";
import { usePaymentMethodStore } from "@/store/payment-method";
import { useCustomerStore } from "@/store/customer";
import { useMessageStore } from "@/store/message";
import { provide, computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { waitForAction } from "@/helpers/wait-for-action";
import { storeToRefs } from "pinia";

const { t, locale } = useI18n();
const { smAndUp } = useDisplay();
const paymentMethodStore = usePaymentMethodStore();
const paymentStore = usePaymentStore();
const customerStore = useCustomerStore();
const appStore = useAppStore();
const basketStore = useBasketStore();
const messageStore = useMessageStore();
const zoid = useZoid();

const emailInputMount = ref<HTMLElement>();

const isSubmitting = ref(false);
const isDisabled = ref(false);
const { selectedSavedPaymentMethod } = storeToRefs(customerStore);

const isFree = computed(() => {
    return basketStore.basketData?.basket.price.balance === 0;
});

const { formatCurrency } = useCurrencyFormatter(
    locale,
    basketStore.currencyCode,
);

const amount = computed(() => {
    if (
        basketStore.showConversion &&
        basketStore.totalWithConversion &&
        paymentMethodStore.selectedPaymentMethodDetails?.currency
    ) {
        return formatCurrency(
            basketStore.totalWithConversion,
            paymentMethodStore.selectedPaymentMethodDetails.currency,
        );
    }

    return formatCurrency(basketStore.balance);
});

const isDataLoading = computed(() => {
    return (
        paymentMethodStore.isPaymentMethodLoading ||
        paymentMethodStore.items.isLoading ||
        appStore.isConfigLoading ||
        basketStore.shouldShowLoader
    );
});

const showSkeleton = computed(() => {
    // Show the skeleton if we are loading the payment methods or the fields are not ready yetp
    return isDataLoading.value === true || paymentStore.isFieldsReady === false;
});

const submitButtonContainer = ref(null);
provide("submitButtonContainer", submitButtonContainer);
provide("setFieldsDisabled", (value: boolean) => {
    isDisabled.value = value;
});

const secondaryActionsContainer = ref<HTMLElement | null>(null);
provide("secondaryActionsContainer", secondaryActionsContainer);

const onSubmit = (...args: any[]) => {
    const form = paymentStore.getActiveForm();

    if (!form) {
        throw new Error("No active form found");
    }

    form.handleSubmit(async (data) => {
        isSubmitting.value = true;

        // Make the payment
        try {
            if (basketStore.isPaymentMethodUpdate) {
                await paymentStore.updatePaymentMethod(data);
            } else {
                const payload: Record<string, any> = {
                    ...data,
                    // If the user is in a zoid popup, close the popup after payment
                    completeAction: zoid.isChild() ? "close" : "modern",
                };

                // Ensure the user is not saving a new payment method
                if (!form.values.savedPaymentsActive) {
                    // If the user has selected a saved payment method, pass the token
                    if (selectedSavedPaymentMethod.value?.uuid) {
                        payload.savedPaymentToken =
                            selectedSavedPaymentMethod.value.uuid;
                    }
                }

                // Perform request
                await paymentStore.makePayment(payload);
            }
        } catch (error) {
            if (isAxiosError<ErrorResponse>(error)) {
                const message =
                    error.response?.data.message ?? t("error.unexpected");
                messageStore.addMessage(message, { type: "error" });
            }

            throwErrorIfNecessary(error, "Error making payment");
        } finally {
            isSubmitting.value = false;
        }
    })(...args);
};

// Provide the submit handler to the fields
provide("submitHandler", onSubmit);

onMounted(async () => {
    // If the basket hasn't loaded yet, wait for it to load
    if (!basketStore.isReady) {
        await waitForAction(basketStore, "fetchBasket");
    }

    if (basketStore.address?.country === "XX") {
        router.push({ name: "country-selection" });
    } else {
        await paymentMethodStore.fetchPaymentMethods();

        if (isFree.value) {
            paymentMethodStore.setSelectedPaymentMethodIndex(0);
        }
    }
});

// TODO: in future I think we should rethink this logic as it is getting a bit complex
const shouldShowSubmitButton = computed(() => {
    // If the checkout is in test mode, always display standard submit button
    if (appStore.config?.testMode) {
        return true;
    }

    // For cashapp we want to show the button associated with cashapp
    if (paymentMethodStore.selectedPaymentMethod?.ident === "cashapp") {
        return false;
    }

    const hasLocalPaymentMethodSelected = ["googlepay", "applepay"].includes(
        paymentMethodStore.selectedPaymentMethod?.ident ?? "",
    );

    // If they have not selected a local payment method show the regular submit button
    if (!hasLocalPaymentMethodSelected) {
        return true;
    }

    // If they are not within zoid show the button associated with apple or google pay
    if (!zoid.isChild()) {
        return false;
    }

    // If they are within zoid they can either be in a popup or embedded
    // For embedded we want to show the regular button
    if (zoid.xprops.isEmbedded) {
        return true;
    } else {
        return false;
    }
});

const submitButtonLabel = computed(() => {
    if (isFree.value) {
        return t("payment.form.complete_order");
    }

    const { ident } = paymentMethodStore.selectedPaymentMethod ?? {};

    if (
        !appStore.config?.testMode &&
        (ident === "googlepay" || ident === "applepay")
    ) {
        return t("buttons.continue");
    }

    if (basketStore.isPaymentMethodUpdate) {
        return t("buttons.update_payment_method");
    }

    return t("payment.form.pay_with", {
        amount: amount.value,
        method: paymentMethodStore.selectedPaymentMethod?.name,
    });
});
</script>

<style scoped lang="scss">
@use "@/styles/settings.scss";
@use "@/styles/mixins/theme";

.terms-checkbox :deep(label) {
    font-size: 11px;
}

.show-more-payments {
    margin-top: 7px;
    text-decoration: underline;
}

// Customise the skeleton loader for the form
.payment-details-skeleton {
    :deep(.v-skeleton-loader__field) {
        padding-block: 4px;

        &:nth-of-type(1) {
            margin-right: 4px;
            max-width: calc(50% - 4px);
        }
        &:nth-of-type(2) {
            margin-left: 4px;
            max-width: calc(50% - 4px);
        }

        // @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
        //     &:nth-of-type(1),
        //     &:nth-of-type(2) {
        //         max-width: 50%;
        //     }
        // }
    }
}

.payment-details,
.payment-methods-container {
    position: relative;
    &--disabled {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            @include theme.dark {
                background-color: rgba(0, 0, 0, 0.5);
            }

            @include theme.light {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
</style>
