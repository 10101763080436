<template>
    <Teleport
        v-if="submitButtonContainer"
        :to="submitButtonContainer.$el"
    >
        <CashAppButton
            v-if="!isZoidEmbed"
            :config="config"
            :interceptClick="onCashAppClick"
            @change="onCashAppChange"
            @error="onCashAppError"
        />
        <v-btn
            v-else
            color="primary"
            width="100%"
            :disabled="appStore.isApiRunning"
            @click="onContinue"
        >
            {{ t("buttons.continue") }}
        </v-btn>
    </Teleport>
    <span v-if="error">
        {{ error }}
    </span>
</template>

<script setup lang="ts">
import {
    onMounted,
    onBeforeUnmount,
    unref,
    inject,
    Ref,
    ComponentPublicInstance,
    toRef,
    ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import { PaymentFormContext, usePaymentStore } from "@/store/payment";
import { useBasketStore } from "@/store/basket";
import { useMessageStore } from "@/store/message";
import { type FieldsCashapp } from "@/types/PaymentMethodFields";
import { useZoid } from "@/composables/useZoid";
import { popup } from "@/helpers/popup";
import router from "@/plugins/router";
import CashAppButton from "@/components/payment/CashAppButton.vue";
import { throwErrorIfNecessary } from "@/services/api";
import { assert, err } from "@/helpers/assert";

interface Props {
    config: FieldsCashapp;
    form: PaymentFormContext;
    fieldsUpdating: Set<string>;
}

const props = defineProps<Props>();
const form = unref(props.form);
const zoid = useZoid();
const isZoidEmbed = zoid.isChild() && zoid.xprops.isEmbedded;

const appStore = useAppStore();
const paymentStore = usePaymentStore();
const basketStore = useBasketStore();
const messageStore = useMessageStore();
const { t } = useI18n();
const submitButtonContainer = inject<Ref<ComponentPublicInstance>>(
    "submitButtonContainer",
);

const submitHandler = inject<Function>("submitHandler");
const fieldsUpdating = toRef(props, "fieldsUpdating");

const error = ref<string | null>(null);

const openCashappWindow = async () => {
    const data = form?.values;

    const { href } = await router.resolve({
        name: "cashapp",
        params: {
            basketId: basketStore.basketId
        },
    });

    const newWindow = popup(href, "_blank", {
        popup: true,
        toolbar: false,
        location: false,
        center: true,
        width: 610,
        height: 500,
    });

    assert(newWindow !== null, "Failed to open new window");
    newWindow.focus();

    // Add the payment data and config to the new window
    (newWindow as any).TBX_PAYMENT_DATA = data;
    (newWindow as any).TBX_CASHAPP_CONFIG = props.config;
    (newWindow as any).TBX_THEME = window.xprops?.theme ?? "light";

    // Wait for the new window to close
    return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(interval);
                resolve();
            }
        }, 100);
    });
};

const onContinue = async () => {
    const { valid } = await form.validate();

    if (!valid) {
        return;
    }

    basketStore.setPaymentInProgress(true);

    await router.push({ name: "payment-waiting" });

    try {
        await openCashappWindow();
        await paymentStore.completePayment();
    } catch (error) {
        // Ensure the user doesn't sit on the payment-waiting page forever if there is an error
        await router.push({ name: "payment-error" });
        throwErrorIfNecessary(error);
    }
};

const onCashAppClick = async (resolve: Function) => {
    // Don't submit the form if the user is updating their post code
    // This is because the amount may change if the user is updating their address
    if (fieldsUpdating.value.has("postalCode")) {
        return;
    }

    const { valid } = await form.validate();

    if (valid) {
        resolve();
    }
};

const onCashAppChange = (state: any) => {
    form.setValues(state.data, false);

    if (state.isValid && submitHandler) {
        submitHandler();
    }
};

const onCashAppError = async (message: string) => {
    messageStore.addMessage(message, {
        type: "error",
    });
};

onMounted(async () => {
    setTimeout(() => {
        paymentStore.setFieldsReady(true);
    }, 400);
});

onBeforeUnmount(() => {
    paymentStore.setFieldsReady(false);
});
</script>
