<template>
    <!-- Standard fields that apply to all methods -->
    <div class="d-flex flex-column w-100">
        <Teleport
            :to="emailInputMountTarget"
            :disabled="!emailInputMountTarget"
        >
            <Email
                v-model="form.fields.email.model"
                v-bind="form.fields.email.props"
                @blur="onBlur('email')"
            />
        </Teleport>

        <v-row dense v-if="showForm">
            <v-col cols="6">
                <label for="name">
                    {{ t("payment.form.full_name") }}
                </label>
                <v-text-field
                    v-model="form.fields.name.model"
                    v-bind="form.fields.name.props"
                    @blur="onBlur('name')"
                    id="name"
                    name="name"
                    :placeholder="t('payment.form.full_name_placeholder')"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <label for="postalCode">
                    {{ t("payment.form.postal_code") }}
                </label>
                <v-text-field
                    v-model="form.fields.postalCode.model"
                    v-bind="form.fields.postalCode.props"
                    @blur="onBlur('postalCode')"
                    id="postalCode"
                    name="postalCode"
                    placeholder="PO12 1AB"
                    :loading="fieldsUpdating.has('postalCode')"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>

    <!-- Fields related to the payment method are rendered below -->
    <div class="w-100" v-if="showForm">
        <component
            :is="component"
            :form="form"
            :config="paymentMethodDetails.fields"
            :fieldsUpdating="fieldsUpdating"
        ></component>
    </div>

    <SavedPaymentMethodSelector
        v-else
        v-model="customerStore.selectedSavedPaymentMethod"
        :items="availableSavedPaymentMethods"
        :loading="false"
        :selection-change-pending="savedMethodIsUpdating"
        :selected-payment-method-ident="
            paymentMethodStore.selectedPaymentMethod?.ident
        "
        @add-new-method="addNewMethod"
    />

    <div class="text-error mt-n5 mb-n3" style="height: 24px">
        {{ errorMessage }}
    </div>

    <v-row dense v-if="paymentMethodDetails?.methodCanBeSaved && showForm">
        <v-col>
            <v-switch
                v-model="form.fields.savedPaymentsActive.model"
                class="v-switch-label--first"
                color="primary"
                inset
                hide-details
            >
                <template #label>
                    {{ t("payment.saved_payments.toggle") }}
                </template>
            </v-switch>
        </v-col>
    </v-row>

    <v-row dense>
        <v-col>
            <v-checkbox
                v-model="form.fields.gdprConsent.model"
                v-bind="form.fields.gdprConsent.props"
                density="compact"
                hide-details
                class="mb-3 mt-2"
            >
                <template v-slot:label>
                    {{
                        t("payment.marketing", {
                            name: appStore.config?.name ?? "",
                        })
                    }}
                </template>
            </v-checkbox>
            <v-checkbox
                v-model="form.fields.termsAccepted.model"
                v-bind="form.fields.termsAccepted.props"
                density="compact"
            >
                <template v-slot:label>
                    <TermsAndConditionsLink />
                </template>
            </v-checkbox>
        </v-col>
    </v-row>

    <!-- Show a button at the bottom of the form to allow the user to view their saved payment methods -->
    <Teleport
        v-if="
            secondaryActionsContainer &&
            paymentMethodStore.selectedPaymentMethod?.ident === 'globalcards' &&
            addingNewSavedPaymentMethod === true
        "
        :to="secondaryActionsContainer"
    >
        <v-btn
            width="100%"
            height="30px"
            class="text-sm border"
            @click="backToSavedCards"
        >
            {{ t("buttons.back_to_saved_cards") }}
        </v-btn>
    </Teleport>
</template>

<script setup lang="ts">
import { PaymentMethodDetails } from "@/types/PaymentMethod";
import { toRef, ref, watch, computed, type Component, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { getErrorMessage } from "@/helpers/error";
import { storeToRefs } from "pinia";
import * as Sentry from "@sentry/browser";
import { BillingAddress } from "@/types/BillingAddress";
import TermsAndConditionsLink from "./TermsAndConditionsLink.vue";
import SavedPaymentMethodSelector from "@/components/payment/SavedPaymentMethodSelector.vue";

import FieldsAdyen from "./fields/FieldsAdyen.vue";
import FieldsApplePay from "./fields/FieldsApplePay.vue";
import FieldsDynamic from "./fields/FieldsDynamic.vue";
import FieldsEbanx from "./fields/FieldsEbanx.vue";
import FieldsGooglePay from "./fields/FieldsGooglePay.vue";
import FieldsInstructions from "./fields/FieldsInstructions.vue";
import FieldsNone from "./fields/FieldsNone.vue";
import FieldsCashApp from "./fields/FieldsCashapp.vue";
import FieldsPayPal from "./fields/FieldsPayPal.vue";

import { usePaymentStore } from "@/store/payment";
import { usePaymentForm } from "@/composables/usePaymentForm";
import { usePaymentMethodStore } from "@/store/payment-method";
import Email from "../inputs/Email.vue";
import { SavedPaymentMethod } from "@/types/SavedPaymentMethod";
import { useCustomerStore } from "@/store/customer";
import { required } from "@vee-validate/rules";

const FieldComponents: {
    [key: string]: Component;
} = {
    FieldsAdyen,
    FieldsApplePay,
    FieldsDynamic,
    FieldsEbanx,
    FieldsGooglePay,
    FieldsInstructions,
    FieldsNone,
    FieldsCashApp,
    FieldsPayPal,
};

interface Props {
    paymentMethodDetails: PaymentMethodDetails;
    emailInputMountTarget?: HTMLElement;
}

const props = defineProps<Props>();

const paymentMethodDetails = toRef(() => props.paymentMethodDetails);
const { t } = useI18n();
const appStore = useAppStore();
const basketStore = useBasketStore();
const paymentStore = usePaymentStore();
const paymentMethodStore = usePaymentMethodStore();
const customerStore = useCustomerStore();

const secondaryActionsContainer = inject<HTMLElement | null>(
    "secondaryActionsContainer",
);

const { address } = storeToRefs(basketStore);
let initialValuesSet = false;

// Default schema for the form
const defaultSchema = {
    name: (value: unknown) =>
        hasSavedPaymentMethods.value &&
        !!customerStore.selectedSavedPaymentMethod
            ? true
            : required(value),
    postalCode: (value: unknown) =>
        hasSavedPaymentMethods.value &&
        !!customerStore.selectedSavedPaymentMethod
            ? true
            : required(value),
    email: "required|email",
    termsAccepted: "required",
    gdprConsent: "",
    savedPaymentsActive: "",
};

const { form } = usePaymentForm(
    paymentMethodDetails,
    defaultSchema,
    // Skip extra field validation if the user has selected a saved payment method
    computed(() => !!customerStore.selectedSavedPaymentMethod),
);
paymentStore.setActiveForm(form);

watch(
    () => address.value,
    () => {
        if (address.value !== null && !initialValuesSet) {
            const values = {
                email: address.value?.email,
                name: address.value?.name,
                postalCode: address.value?.postalCode,
                country: address.value?.country,
            };

            for (const [key, value] of Object.entries(values)) {
                form.setFieldValue(key, value, false);
            }

            initialValuesSet = true;
        }
    },
    { immediate: true },
);

// Disable saving of payment methods when the user selects a saved payment method
watch(
    () => customerStore.selectedSavedPaymentMethod,
    () => form.setFieldValue("savedPaymentsActive", false),
);

const fieldsUpdating = ref(new Set<string>());

const savedMethodIsUpdating = ref(false);
const errorMessage = ref("");

const onBlur = async (fieldName: string) => {
    const { valid } = await form.validateField(fieldName);
    const fieldValue = form.values[fieldName];
    let currentValue = address.value?.[fieldName as keyof BillingAddress];

    // Update the basket with the field value if it is valid and has changed
    if (valid && fieldValue !== currentValue) {
        fieldsUpdating.value.add(fieldName);

        try {
            await basketStore.updateBasket({ [fieldName]: fieldValue });
            currentValue = fieldValue;
        } catch (error) {
            const message = getErrorMessage(error);
            form.setFieldError(fieldName, message);
        }

        // We need to refresh the selected payment method details as the basket has changed
        try {
            await paymentMethodStore.fetchSelectedPaymentMethodDetails(true);
        } catch (error) {
            const message = getErrorMessage(error);
            form.setFieldError(fieldName, message);
        }

        fieldsUpdating.value.delete(fieldName);
    }
};

const component = computed(() => {
    let component = paymentMethodDetails.value?.fields?.component;

    if (!component) {
        component = "FieldsNone";
    }

    return FieldComponents[component];
});

const availableSavedPaymentMethods = computed<SavedPaymentMethod[]>(() => {
    const currPaymentMethod = paymentMethodStore.selectedPaymentMethod;
    const savedMethods = customerStore.savedPaymentMethods;

    if (currPaymentMethod === null) return [];

    if (savedMethods === null) return [];

    return savedMethods.filter(
        (item) => item.payment_method === currPaymentMethod.ident,
    );
});

const hasSavedPaymentMethods = computed(() => {
    return availableSavedPaymentMethods.value.length > 0;
});

const addingNewSavedPaymentMethod = ref(false);

watch(
    [
        availableSavedPaymentMethods,
        () => customerStore.selectedSavedPaymentMethod,
    ],
    ([list, selected]) => {
        if (list.length === 0) customerStore.selectedSavedPaymentMethod = null;
        // If the selected method isn't in the current filtered list, then select a new one
        else if (
            !list.some(
                (item) => item?.payment_method === selected?.payment_method,
            ) &&
            addingNewSavedPaymentMethod.value === false
        )
            customerStore.selectedSavedPaymentMethod = list[0];
    },
    { immediate: true },
);

// Update basket to match saved payment method postcode
watch(
    () => customerStore.selectedSavedPaymentMethod,
    async (selectedSavedMethod) => {
        if (!selectedSavedMethod) return;

        savedMethodIsUpdating.value = true;
        errorMessage.value = "";

        try {
            const postalCode = selectedSavedMethod.description.postal_code;
            await basketStore.updateBasket({ postalCode });
            await paymentMethodStore.fetchSelectedPaymentMethodDetails(true);
        } catch (error) {
            const message = getErrorMessage(error);
            Sentry.captureException(
                `Error updating basket for selected payment method - ${message}`,
            );
            errorMessage.value = message;
        } finally {
            savedMethodIsUpdating.value = false;
        }
    },
);

const addNewMethod = () => {
    addingNewSavedPaymentMethod.value = true;
    customerStore.selectedSavedPaymentMethod = null;

    // Automatically enable the save method checkbox
    form.setFieldValue("savedPaymentsActive", true);
};

const showForm = computed(() => {
    return !hasSavedPaymentMethods.value || addingNewSavedPaymentMethod.value;
});

const backToSavedCards = () => {
    addingNewSavedPaymentMethod.value = false;
    customerStore.selectedSavedPaymentMethod =
        availableSavedPaymentMethods.value[0];
    form.setFieldValue("savedPaymentsActive", false);
};
</script>
