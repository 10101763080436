import { PaymentMethod, PaymentMethodDetails } from "@/types/PaymentMethod";
import api from "./api";
import { AxiosResponse } from "axios";
import { localPaymentMethodManager } from "./local-payment-method";
interface PaymentMethodResponse {
    data: PaymentMethod[];
}

/**
 * Some payment methods such as apple pay and google pay are not returned by an API.
 * This is because a front-end check is needed to see if the browser supports these payment methods
 */
async function addLocalPaymentMethods(
    paymentMethods: PaymentMethod[],
): Promise<PaymentMethod[]> {
    let insert = 1;

    for (const method of localPaymentMethodManager.getAll()) {
        if (await method.isAvailable()) {
            paymentMethods.splice(insert, 0, {
                name: method.name,
                ident: method.ident,
                image: method.image,
                transparentImage: method.transparentImage,
                countries: method.countries,
                currencies: method.details.currencies,
            });

            insert++;
        }
    }
    return paymentMethods;
}

async function parsePaymentMethodResponse(
    response: AxiosResponse<PaymentMethodResponse>,
): Promise<PaymentMethod[]> {
    const paymentMethodResponse = response.data;

    let paymentMethods = paymentMethodResponse.data;
    let hasTestPaymentMethod = false;
    let hasCheckoutDotComPaymentMethod = false;

    paymentMethods.forEach((method) => {
        if (method.ident === "testmethod") {
            hasTestPaymentMethod = true;
        }

        if (method.ident === "globalcards_checkout") {
            hasCheckoutDotComPaymentMethod = true;
        }

        // Got to map the images to the correct path
        const imageName = method.image.split("/").pop()?.split(".")[0];
        method.image = `/img/brands/light/${imageName}.webp`;
        method.transparentImage = `/img/brands/dark/${imageName}.webp`;
    });

    // globalcards_checkout is an alternative to globalcards;
    // If globalcards_checkout is present, then we can hide the other
    if (hasCheckoutDotComPaymentMethod) {
        paymentMethods = paymentMethods.filter(({ ident }) => ident !== "globalcards");
    }

    if (!hasTestPaymentMethod) {
        await addLocalPaymentMethods(paymentMethods);
    }

    return paymentMethods;
}

export const getAllPaymentMethods = async (
    basketId: string,
): Promise<PaymentMethod[]> => {
    const response = await api.get(`/baskets/${basketId}/payment/method`);
    return await parsePaymentMethodResponse(response);
};

export const getPaymentMethod = async (
    basketId: string,
    ident: string,
): Promise<PaymentMethodDetails> => {
    return api
        .get(`/baskets/${basketId}/payment/method/${ident}`)
        .then((response: AxiosResponse<PaymentMethodDetails>) => {
            const paymentMethodDetailsResponse = response.data;
            return paymentMethodDetailsResponse;
        });
};

// This is a placeholder for when we have a proper way to determine if a payment method is external
const EXCLUDED_PAYMENT_METHODS = [
    "banktransfer",
    "dragonpay_otc_phil",
    "dragonpay_ebanking",
];

export const isExternalPaymentMethod = (ident: string): boolean => {
    return !EXCLUDED_PAYMENT_METHODS.includes(ident);
};
