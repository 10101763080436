import api from "./api";
import FingerprintJS, { type Agent } from "@fingerprintjs/fingerprintjs-pro";
import { warn } from "@/helpers/assert";
import { isProd } from "@/helpers/env";

/**
 * FingerprintJS agent promise
 */
let agent: Agent;

/**
 * Load the FingerprintJS agent
 */
export async function loadFingerprintingAgent() {
    const endpoint = [];
    const scriptUrlPattern = [];

    endpoint.push(FingerprintJS.defaultEndpoint);
    scriptUrlPattern.push(FingerprintJS.defaultScriptUrlPattern);

    // Add the production endpoint and script URL pattern as the default in production
    if (isProd) {
        endpoint.unshift("https://pay.tebex.io/rid/Tv5IpwTcZBH7PqDG");
        scriptUrlPattern.unshift(
            "https://pay.tebex.io/rid/ekDTaW13U5Y8CJ9t/4UnGE2zvs5uSBVYq?apiKey=<apiKey>&loaderVersion=<loaderVersion>",
        );
    } else {
        endpoint.unshift("https://fpjs.tbxstage.net");
        scriptUrlPattern.unshift(
            "https://fpjs.tbxstage.net/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        );
    }

    try {
        agent = await FingerprintJS.load({
            apiKey: isProd ? "yQz7Wg43xIgHnggMct6q" : "qgdVK4wAdcyN7gFePiCA",
            endpoint,
            scriptUrlPattern,
        });
    } catch (error) {
        warn("Failed to load FingerprintJS agent", error);
    }
}

/**
 * Generate a requestId for use in generating a fingerprint
 * @returns A requestId string or undefined if fingerprinting is disabled
 */
export async function getRequestId(): Promise<string | undefined> {
    if (!agent) {
        warn("FingerprintJS agent not loaded");
        return;
    }

    try {
        const fpRequest = await agent.get();
        return fpRequest.requestId;
    } catch (error) {
        warn("Failed to generate requestId", error);
    }
}

/**
 * Generate a requestId and send it to the server to allow the fingerprint to be generated
 * @param basketId
 */
export const fingerprint = async (basketId: string, requestId: string) => {
    const form = new FormData();
    form.append("requestId", requestId);

    return api.put(`/baskets/${basketId}/fingerprint`, form);
};
