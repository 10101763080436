<template>
    <div 
        v-if="!error"
        ref="mountTarget"
        class="w-100"
    />
    <span v-if="error">
        {{ error }}
    </span>
</template>

<script setup lang="ts">
import {
    onMounted,
    onBeforeUnmount,
    watch,
    ref,
    unref,
} from "vue";

import { useAdyen } from "@/composables/useAdyen";
import { type Adyen } from "@/services/adyen";
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { FieldsCashapp } from "@/types/PaymentMethodFields";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import { PaymentAmount } from "@adyen/adyen-web/dist/types/types";
import { getErrorMessage } from "@/helpers/error";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { storeToRefs } from "pinia";
import { until } from "@vueuse/core";
import { assert } from "@/helpers/assert";

interface Props {
    config: FieldsCashapp;
    interceptClick?: (resolve: Function) => void;
}

const props = withDefaults(defineProps<Props>(), {
    interceptClick: (resolve: Function) => resolve()
});

const appStore = useAppStore();
const basketStore = useBasketStore();
const theme = useTheme();

const { isReady } = storeToRefs(basketStore);

const emit = defineEmits<{
    (e: "change", state: any): void;
    (e: "error", message: string): void;
}>();

let component: UIElement | null = null;

const mountTarget = ref<HTMLElement>();
const error = ref<string | null>(null);
const { t } = useI18n();

function calcAmount() {
    assert(appStore.config !== null, "No app config found");
    assert(basketStore.price !== null, "Basket price not found");
    assert(basketStore.totalWithConversion !== null, "Basket total price with conversion not found");

    return {
        currency: "USD", // Cashapp only supports USD
        value: Math.round(basketStore.totalWithConversion * 100),
    };
}

function resolveCashappErrorMessage(err: any) {
    const message = getErrorMessage(err);

    if (message.includes("Payment declined")) {
        return t("payment.payment_declined");
    }

    if (message.includes("Customer dismissed the modal")) {
        return null;
    }

    if (import.meta.env.DEV) {
        return message;
    }

    return t("error.unexpected");
} 

async function createCashApp(checkout: Adyen) {
    let amount: PaymentAmount;

    try {
        amount = calcAmount();
    } catch (err) {
        // If we can't calculate the amount, we can't continue
        const message = import.meta.env.DEV
            ? getErrorMessage(err)
            : t("error.unexpected");
        error.value = message;
        emit("error", message);
        return;
    }

    const { configuration } = props.config.fields.paymentMethods.find(
        (m: any) => m.type === "cashapp",
    );

    component = checkout.create<"cashapp">("cashapp", {
        environment: import.meta.env.DEV ? "TEST" : "LIVE",
        amount,
        configuration: unref(configuration),
        // We only want to store the payment method if the user has recurring items
        storePaymentMethod: basketStore.recurringItems.length > 0,
        button: {
            theme: theme.current.value.dark ? "light" : "dark",
        },
        async onClick({ resolve } : { resolve: Function }) {
            props.interceptClick(resolve);
        },
        onSubmit(state: any) {
            if (state.isValid) {
                emit("change", state);
            }
        },
        onError(err: any) {
            const message = resolveCashappErrorMessage(err);
            // Null message just means the user closed the CashApp modal; don't need to show anything here
            if (message !== null)
                emit("error", message);
        }
    });
}

const { load, checkout } = useAdyen({
    manual: true, // We don't want to load Adyen until we are ready
    onCheckoutCreate: createCashApp,
});

watch(
    () => basketStore.totalWithConversion,
    () => {
        // Update the amount if the total changes by re-mounting the component
        if (component && checkout.value) {
            component.unmount();
            // Re-create the component
            createCashApp(checkout.value);
        }
    },
);

onMounted(async () => {
    await until(isReady).toBe(true);
    await load();

    assert(component !== null, "Could not mount component");
    component.mount(mountTarget.value!);
});

onBeforeUnmount(() => {
    if (component) {
        component.unmount();
    }
});
</script>
