<template>
    <v-app-bar
        v-if="appStore.config?.testMode"
        elevation="0"
        height="30"
        color="warning"
    >
        <v-app-bar-title class="text-center">
            <v-icon color="#ffffff">custom:warning</v-icon>
            <span class="test-payments-warning text-uppercase">{{
                t("payment.test_payments_active")
            }}</span>
        </v-app-bar-title>
    </v-app-bar>
    <Layout />
</template>

<script setup lang="ts">
import Layout from "@/layouts/Layout.vue";
import { useBasketStore } from "@/store/basket";
import { onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppStore } from "@/store/app";
import { useUpsellStore } from "@/store/upsell";
import { useI18n } from "vue-i18n";
import { useZoid } from "@/composables/useZoid";

const basketStore = useBasketStore();
const appStore = useAppStore();
const upsellStore = useUpsellStore();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const zoid = useZoid();

onMounted(async () => {
    try {
        await appStore.fetchAppConfig(
            route.params.basketId as string,
            zoid.xprops?.origin ?? document.referrer,
        );
    } catch (e) {
        router.push({ name: "basket-not-found" });
        return;
    }

    await basketStore.fetchBasket(route.params.basketId as string);
    await upsellStore.fetchUpsells(route.params.basketId as string);

    // Set the Stripe Radar session on the basket
    basketStore.setStripeRadarSession();
    // Set basket fingerprint, this is used for fraud detection when processing payments
    basketStore.setBasketFingerprint();

    if (basketStore.items.length === 0) {
        router.push({ name: "empty-basket" });
    }
});

watch(
    () => basketStore.items,
    () => {
        if (basketStore.items.length === 0) {
            router.push({ name: "empty-basket" });
        }
    },
);
</script>

<style scoped lang="scss">
.test-payments-warning {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
}
</style>
