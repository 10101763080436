import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";
import { assert } from "@/helpers/assert";
import api from "@/services/api";

import type { SavedPaymentMethod } from "@/types/SavedPaymentMethod";

type CustomerLookupResponse = {
    data: {
        uuid: string;
        mobile: string;
    };
};

export const useCustomerStore = defineStore("customer", () => {
    /**
     * UUID retrieved from lookup endpoint
     */
    const uuid = ref("");

    /**
     * Mobile number retrieved from lookup endpoint (might only be partial phone number)
     */
    const phoneNumber = ref("");

    /**
     * Whether the user has logged in (login is entering their email and the verification code)
     */
    const isLoggedIn = ref(false);

    /**
     * Whether the user has skipped the login popup
     */
    const hasSkipped = ref(false);

    /**
     * List of all saved payment methods that the customer has added, retrieved from login
     */
    const savedPaymentMethods = ref<SavedPaymentMethod[] | null>(null);

    /**
     * A list of email addresses that have been looked up and not been found, used to cache the result
     */
    const failedEmailLookupCache: Set<string> = new Set();

    const selectedSavedPaymentMethod = ref<SavedPaymentMethod | null>(null);

    const pendingDeleteDetails = ref(false);

    const _customerExits = () => uuid.value !== "";

    const lookup = async (basketId: string, email: string) => {
        if (failedEmailLookupCache.has(email)) return;

        const response = await api.post<CustomerLookupResponse | undefined>(
            `/baskets/${basketId}/customer-lookup`,
            {
                email,
            },
        );

        // If we don't find a customer, cache the email and return
        if (!response.data || !response.data?.data.uuid) {
            failedEmailLookupCache.add(email);
            return;
        }

        // If we find a customer, ensure we reset the login state
        isLoggedIn.value = false;

        uuid.value = response.data.data.uuid;
        phoneNumber.value = response.data.data.mobile;
    };

    const login = async (basketId: string, code: string) => {
        assert(_customerExits(), "No customer UUID");

        const response = await api.post(`/baskets/${basketId}/customer-login`, {
            code,
            customer_uuid: uuid.value,
        });

        assert(Array.isArray(response.data.data), "Invalid response");

        isLoggedIn.value = true;

        savedPaymentMethods.value = response.data.data;
    };

    const logout = () => {
        isLoggedIn.value = false;
        savedPaymentMethods.value = [];
        selectedSavedPaymentMethod.value = null;
        uuid.value = "";
        phoneNumber.value = "";
    };

    /**
     * User has clicked "remove my details", this prompts the user to enter an OTP
     * the OTP must be sent via confirmResolveDetails()
     */
    const beginRemoveDetails = async () => {
        assert(_customerExits(), "No customer UUID");

        pendingDeleteDetails.value = true;

        await api.delete(`/customer/remove-my-details/verify`, {
            data: {
                customer_uuid: uuid.value,
            },
        });
    };

    /**
     * Cancel "remove my details" if user tries closing the email modal while it is active
     */
    const cancelRemoveDetails = async () => {
        pendingDeleteDetails.value = false;
    };

    /**
     * User has entered their OTP, confirming that they want to remove their details
     */
    const confirmRemoveDetails = async (code: string) => {
        assert(pendingDeleteDetails.value === true); // must call beginRemoveDetails() first

        await api.delete(`/customer/remove-my-details`, {
            data: {
                code,
                customer_uuid: uuid.value,
            },
        });

        pendingDeleteDetails.value = false;

        savedPaymentMethods.value = [];
        selectedSavedPaymentMethod.value = null;

        isLoggedIn.value = false;
        uuid.value = "";
    };

    /**
     * Remove an individual saved payment method
     */
    const removeSavedPaymentMethod = async (
        basketId: string,
        methodUuid: SavedPaymentMethod["uuid"],
    ) => {
        await api.delete(
            `/baskets/${basketId}/customer/delete-payment-method/${methodUuid}`,
        );

        if (savedPaymentMethods.value === null) return;

        savedPaymentMethods.value = savedPaymentMethods.value?.filter(
            (item) => {
                return item.uuid !== methodUuid;
            },
        );
    };

    return {
        uuid,
        phoneNumber,
        isLoggedIn,
        hasSkipped,
        savedPaymentMethods,
        selectedSavedPaymentMethod,
        pendingDeleteDetails,
        lookup,
        login,
        logout,
        beginRemoveDetails,
        confirmRemoveDetails,
        cancelRemoveDetails,
        removeSavedPaymentMethod,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCustomerStore, import.meta.hot));
}
