import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

let stripe: Stripe | null = null;

export async function _loadStripe(publishableKey: string) {
    if (!stripe) {
        stripe = await loadStripe(publishableKey);
    }

    return stripe;
}

export async function initStripeRadarsession(
    publishableKey: string,
): Promise<Record<any, any>> {
    await _loadStripe(publishableKey);

    if (!stripe) {
        throw new Error("Stripe not initialized");
    }

    const { radarSession, error } = await stripe.createRadarSession();

    if (error) {
        throw new Error(error.message);
    }

    return radarSession;
}
