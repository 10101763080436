type AssertFn = (condition: boolean, err?: string) => asserts condition;

/**
 * @internal
 */
export const err = (msg = "") => {
    throw new Error("Error" + (msg ? ":: " : "") + msg.trim());
};

/**
 * @internal
 */
export const warn = (msg = "", ...args: any[]) => {
    console.warn("Warn " + (msg ? ":: " : "") + msg.trim(), ...args);
};

/**
 * @internal
 */
export const assert: AssertFn = (condition, msg = "assert failed") => {
    if (!condition) err(msg);
};
