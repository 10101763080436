import { ref, type Ref } from "vue";
import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { useBasketStore } from "./basket";
import { useRouter } from "vue-router";
import { usePaymentMethodStore } from "./payment-method";
import { postCheckout, postPaymentMethod } from "@/services/basket";
import { SetupResponse } from "@/types/SetupResponse";
import { localPaymentMethodManager } from "@/services/local-payment-method";
import popupManager from "@/helpers/popup-manager";
import zoid from "@/composables/useZoid";
import { isExternalPaymentMethod } from "@/services/payment-method";
import { type PaymentFormContext } from "@/composables/usePaymentForm";
import { useAppStore } from "./app";
import { throwErrorIfNecessary } from "@/services/api";
export { type PaymentFormContext } from "@/composables/usePaymentForm";
import * as Sentry from "@sentry/browser";

interface PaymentState {
    setupResponse: Ref<SetupResponse | null>;
    isFieldsReady: Ref<boolean>;
    makePayment: (
        data: Record<string, any>,
        options?: {
            skipPopupCreation?: boolean;
            paymentMethodFallback?: string | null;
        },
    ) => Promise<void>;
    completePayment: () => Promise<void>;
    setPaymentData: (data: Record<string, any>) => void;
    setFieldsReady: (value: boolean) => void;
    updatePaymentMethod: (data: Record<string, string>) => Promise<void>;
    getActiveForm: () => PaymentFormContext | null;
    setActiveForm: (form: PaymentFormContext) => void;
}

export const usePaymentStore = defineStore(
    "payment",
    function (): PaymentState {
        const router = useRouter();
        const paymentMethodStore = usePaymentMethodStore();
        const basketStore = useBasketStore();
        const appStore = useAppStore();
        const setupResponse = ref<SetupResponse | null>(null);
        const paymentData = ref<Record<string, string>>({});
        const { radarSession } = storeToRefs(basketStore);

        function setPaymentData(data: Record<string, any>) {
            paymentData.value = data;
        }

        async function completePayment() {
            // Refresh the basket
            if (basketStore.basketId) {
                await basketStore.fetchBasket(basketStore.basketId);
            }

            // If there is no payment then we need to redirect back to the payment page
            if (basketStore.payment === null) {
                await router.push({ name: "payment" });
                basketStore.setPaymentInProgress(false);
                return;
            }

            // Was the payment declined?
            if (basketStore.payment && "reason" in basketStore.payment) {
                await router.push({ name: "payment-error" });
                return;
            }

            // If the payment method can be saved then we need to redirect to the next step
            if (basketStore.payment?.savePaymentMethodStep) {
                await router.push({
                    name: basketStore.payment.savePaymentMethodStep,
                });

                return;
            }

            // Otherwise we can redirect to the payment completed page
            await router.push({ name: "payment-completed" });
        }

        async function handleLocalPaymentMethod(
            basketId: string,
            ident: string,
            data: Record<string, any>,
        ) {
            const localMethod = localPaymentMethodManager.getMethod(ident);

            if (!localMethod) {
                throw new Error(`No local payment method found for ${ident}`);
            }

            basketStore.setPaymentInProgress(true);

            // Redirect to the payment-waiting page
            await router.push({ name: "payment-waiting" });

            try {
                if (localMethod.shouldRedirect()) {
                    await localPaymentMethodManager.redirect(
                        ident,
                        basketId,
                        data,
                    );
                } else {
                    // If the payment method doesn't require a redirect then we can post the payment data to the server
                    await postCheckout(basketId, data);
                }
            } catch (error) {
                // Ensure the user doesn't sit on the payment-waiting page forever if there is an error
                await router.push({ name: "payment-error" });
                throwErrorIfNecessary(error);
            }

            // Once the redirect is complete we can complete the payment
            await completePayment();
        }

        async function handlePaymentMethod(
            basketId: string,
            data: Record<string, any>,
            skipPopupCreation = false,
        ) {
            if (
                !skipPopupCreation &&
                zoid.isChild() &&
                isExternalPaymentMethod(
                    paymentMethodStore.selectedPaymentMethod?.ident ?? "",
                )
            ) {
                // To get around browsers being annoying with popup windows we need to
                // immediately create and then reuse the popup later
                popupManager.createOrReusePopup();
            }

            let response;

            try {
                response = await postCheckout(basketId, data);
            } catch (error) {
                popupManager.closePopup();
                throw error;
            }

            await router.push({ name: "payment-waiting" });

            basketStore.setPaymentInProgress(true);

            setupResponse.value = response;

            // Redirect to the payment page
            await router.push({ name: "setup-response" });
        }

        async function makePayment(
            data: Record<string, any>,
            {
                skipPopupCreation = false,
                paymentMethodFallback = null as string | null,
            } = {},
        ) {
            if (!basketStore.basketId) {
                throw new Error("No basketId");
            }

            if (
                !paymentMethodStore.selectedPaymentMethod &&
                !paymentMethodFallback
            ) {
                throw new Error("No payment method selected");
            }

            const requestData = {
                ...paymentData.value,
                ...data,
                radar_session_id: radarSession.value?.["id"] ?? null,
            };

            const ident =
                paymentMethodStore.selectedPaymentMethod?.ident ??
                paymentMethodFallback;

            if (!ident) {
                throw new Error("No payment method ident found");
            }

            // Save the payment method for next time
            paymentMethodStore.saveLastUsedPaymentMethod(ident);

            // If the selected payment method is a local payment method then we need to handle it differently
            if (
                localPaymentMethodManager.isLocalPaymentMethod(ident) &&
                !appStore.config?.testMode
            ) {
                await handleLocalPaymentMethod(
                    basketStore.basketId,
                    ident,
                    requestData,
                );
            } else {
                await handlePaymentMethod(
                    basketStore.basketId,
                    requestData,
                    skipPopupCreation,
                );
            }
        }

        async function updatePaymentMethod(data: Record<string, string>) {
            if (!basketStore.basketId) {
                throw new Error("No basketId");
            }

            await postPaymentMethod(basketStore.basketId, data);
        }

        const isFieldsReady = ref(false);

        function setFieldsReady(value: boolean) {
            isFieldsReady.value = value;
        }

        const activeForm: Ref<PaymentFormContext | null> = ref(null);

        function getActiveForm(): PaymentFormContext | null {
            return activeForm.value;
        }

        function setActiveForm(form: PaymentFormContext) {
            activeForm.value = form;
        }

        return {
            setupResponse,
            isFieldsReady,
            makePayment,
            completePayment,
            setPaymentData,
            setFieldsReady,
            updatePaymentMethod,
            getActiveForm,
            setActiveForm,
        };
    },
);

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePaymentStore, import.meta.hot));
}
