<template>
    <div class="cashapp-container">
        <v-skeleton-loader
            :loading="!appStore.config || basketStore.shouldShowLoader"
            type="heading, list-item-two-line@4, button"
        >
            <v-card
                v-if="!showLoader"
                class="h-100 d-flex flex-column"
                :elevation="0"
            >
                <v-card-title>
                    <img
                        width="80px"
                        :src="`/img/brands/${
                            theme.current.value.dark ? 'dark' : 'light'
                        }/cashapp.webp`"
                    />
                    <h2 class="text-h5 text-right flex-grow-1">
                        {{ t("basket.total") }}
                        <span class="font-weight-bold">
                            {{ formatCurrency(basketStore.balance) }}
                        </span>
                    </h2>
                </v-card-title>

                <v-card-item class="px-5 mt-4">
                    <BasketListItem
                        v-for="item in basketStore.items"
                        :key="item.id"
                        :item="item"
                        :currency-code="basketStore.currencyCode"
                    />
                </v-card-item>

                <v-card-actions class="mt-auto px-5">
                    <CashAppButton
                        :config="config"
                        @change="onCashAppChange"
                        @error="onCashAppError"
                    />
                </v-card-actions>
            </v-card>

            <div
                v-else
                class="h-100 w-100 d-flex flex-column align-center justify-center"
            >
                <Loader />
                <div class="mt-3">{{ t("ui.loading") }}</div>
            </div>
        </v-skeleton-loader>
    </div>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { useBasketStore } from "@/store/basket";
import { usePaymentMethodStore } from "@/store/payment-method";
import { postCheckout } from "@/services/basket";
import { useTheme } from "vuetify/lib/framework.mjs";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrencyFormatter } from "@/composables/useCurrencyFormatter";
import { handleError } from "@/services/api";
import { storeToRefs } from "pinia";
import Loader from "@/components/app/Loader.vue";
import BasketListItem from "@/components/basket/BasketListItem.vue";
import CashAppButton from "@/components/payment/CashAppButton.vue";
import { assert } from "@/helpers/assert";

const appStore = useAppStore();
const basketStore = useBasketStore();
const paymentMethodStore = usePaymentMethodStore();
const { t, locale } = useI18n();

const theme = useTheme();
theme.global.name.value = (window as any).TBX_THEME ?? "light";

const config = ref((window as any).TBX_CASHAPP_CONFIG);
const showLoader = ref(true);
const { currencyCode } = storeToRefs(basketStore);

const { formatCurrency } = useCurrencyFormatter(locale, currencyCode);

const processPayment = async (data: Record<string, any>) => {
    assert(basketStore.basketId != undefined, "No basket ID found");

    try {
        await postCheckout(basketStore.basketId, data);
    } catch (error) {
        handleError(error, { showToast: false });
    }
};

const onCashAppError = async (message: string) => {
    // Send error message to parent window
    window.opener.postMessage(
        {
            name: "tbx",
            payload: {
                type: "error",
                message,
            },
        },
        // Remove trailing slash from URL
        window.location.href.replace(/\/$/, ""),
    );

    window.close();
};

const onCashAppChange = async (state: any) => {
    showLoader.value = true;
    const data = state.data;

    try {
        await processPayment({
            ...(window as any).TBX_PAYMENT_DATA,
            ...data,
        });
    } catch (error: any) {
        onCashAppError(error);
    }

    window.close();
};

onMounted(async () => {
    await paymentMethodStore.fetchPaymentMethods();
    paymentMethodStore.setSelectedPaymentMethodIdent("cashapp");
    await paymentMethodStore.fetchSelectedPaymentMethodDetails();
    showLoader.value = false;
});
</script>

<style lang="scss">
@use "@/styles/mixins/theme";
.cashapp-container {
    height: 100%;

    .v-card {
        height: 100%;
        width: 100%;
    }

    .v-skeleton-loader[aria-busy="true"] {
        padding: 8px 24px;
        height: 100%;
        align-items: flex-start;

        .v-skeleton-loader__button {
            margin-top: auto;
            max-width: 100%;
        }
    }

    .v-skeleton-loader[aria-busy="false"] {
        height: 100%;
    }

    .v-card-title {
        @include theme.light {
            background-color: #f2f2f2;
        }

        @include theme.dark {
            background-color: #333333;
        }

        display: flex;
        align-items: center;
        height: 80px;
    }
}
</style>
