<template>
    <div class="d-flex flex-column align-center justify-center w-100 h-100">
        <vue-turnstile
            v-show="!isLoading"
            v-model="token"
            site-key="0x4AAAAAAAQg9qOmfjGO0H9P"
            appearance="interaction-only"
            :theme="theme.current.value.dark ? 'dark' : 'light'"
            @before-interactive="onBeforeInteractive"
            @error="onError"
        />

        <v-btn
            v-if="isInteractive && !isLoading"
            class="mt-2"
            color="primary"
            :disabled="!token"
            @click="onContinue"
            width="200px"
        >
            {{ t("buttons.continue") }}
        </v-btn>

        <div v-if="isLoading" class="d-flex flex-column align-center">
            <Loader class="mb-8" />

            <div class="text-center mb-11">
                {{ t("ui.loading") }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import { useTheme } from "vuetify";
import VueTurnstile from "vue-turnstile";
import { usePaymentStore } from "@/store/payment";
import { useZoid } from "@/composables/useZoid";
import { useI18n } from "vue-i18n";
import { throwErrorIfNecessary } from "@/services/api";
import { isAxiosError } from "axios";
import { ErrorResponse } from "@/types/Api";
import { useMessageStore } from "@/store/message";
import { useRouter } from "vue-router";
import Loader from "@/components/app/Loader.vue";
import * as Sentry from "@sentry/browser";
import popupManager from "@/helpers/popup-manager";
import { useCustomerStore } from "@/store/customer";
import { storeToRefs } from "pinia";

const theme = useTheme();
const paymentStore = usePaymentStore();
const messageStore = useMessageStore();
const customerStore = useCustomerStore();
const router = useRouter();
const zoid = useZoid();
const { t } = useI18n();

const token = ref<string>("");
const isLoading = ref(true);
const isInteractive = ref(false);
const { selectedSavedPaymentMethod } = storeToRefs(customerStore);

/**
 * Submit the payment with the captcha token
 * @param token The token to submit
 */
async function submitPayment(token: string) {
    const data = paymentStore.getActiveForm()?.values;

    if (!data) {
        console.error("No payment data found");
        return;
    }

    try {
        const payload: Record<string, any> = {
            ...data,
            // If the user is in a zoid popup, close the popup after payment
            completeAction: zoid.isChild() ? "close" : "modern",
            turnstileToken: token,
        };

        // Ensure the user is not saving a new payment method
        if (!data.savedPaymentsActive) {
            // If the user has selected a saved payment method, pass the token
            if (selectedSavedPaymentMethod.value?.uuid) {
                payload.savedPaymentToken =
                    selectedSavedPaymentMethod.value.uuid;
            }
        }

        // Perform request
        await paymentStore.makePayment(payload);
    } catch (error) {
        if (isAxiosError<ErrorResponse>(error)) {
            const message =
                error.response?.data.message ?? t("error.unexpected");
            messageStore.addMessage(message, { type: "error" });
        }

        router.push({ name: "payment" });
        throwErrorIfNecessary(error, "Error making payment");
        popupManager.closePopup();
    }
}

watchEffect(async () => {
    if (!token.value) {
        return;
    }

    // Ensure the captcha is interactive
    if (!isInteractive.value) {
        console.log("Captcha is not interactive, submitting");

        await submitPayment(token.value);
    }
});

/**
 * Set the component to interactive mode
 */
function onBeforeInteractive() {
    isInteractive.value = true;
    isLoading.value = false;

    // Close any open popups when the component is mounted
    popupManager.closePopup();
}

/**
 * Continue with the payment after the captcha has been completed in interactive mode
 */
function onContinue() {
    if (!token.value) {
        return;
    }

    isLoading.value = true;

    submitPayment(token.value);
}

/**
 * The error callback handler
 * @param errorCode The error code from the turnstile captcha
 */
function onError(errorCode: string) {
    Sentry.captureException("Recaptcha error " + errorCode);
    messageStore.addMessage(t("payment.payment_declined_invalid_captcha"), {
        type: "error",
    });
    router.push({ name: "payment" });
}
</script>
